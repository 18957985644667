var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4 mb-base" }, [
    _c("div", { staticClass: "mb-base" }, [
      _c("h6", { staticClass: "mb-4" }, [_vm._v("Activity")]),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.taskReminder,
              callback: function ($$v) {
                _vm.taskReminder = $$v
              },
              expression: "taskReminder",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("Email me task reminders:"),
          ]),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.taskAssigned,
              callback: function ($$v) {
                _vm.taskAssigned = $$v
              },
              expression: "taskAssigned",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("Email me when I get tasks assigned to me:"),
          ]),
        ],
        1
      ),
    ]),
    _c("div", [
      _c("h6", { staticClass: "mb-4" }, [_vm._v("Application")]),
      _c(
        "div",
        { staticClass: "flex items-center mb-4" },
        [
          _c("vs-switch", {
            model: {
              value: _vm.newsAndAnnouncement,
              callback: function ($$v) {
                _vm.newsAndAnnouncement = $$v
              },
              expression: "newsAndAnnouncement",
            },
          }),
          _c("span", { staticClass: "ml-4" }, [
            _vm._v("News and announcements"),
          ]),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "flex flex-wrap items-center justify-end mt-base" },
      [
        _c(
          "vs-button",
          { staticClass: "ml-auto mt-2", on: { click: _vm.saveNotifications } },
          [_vm._v("Save Changes")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }