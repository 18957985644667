var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-4 mb-base" },
    [
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center mb-base" },
        [
          _c("vs-avatar", {
            staticClass: "mr-4 mb-4",
            attrs: { src: _vm.form.profilePictureUrl, size: "70px" },
            on: {
              click: function ($event) {
                return _vm.$refs.profilePictureInput.click()
              },
            },
          }),
          _c(
            "div",
            [
              _c("input", {
                ref: "profilePictureInput",
                attrs: { type: "file", hidden: "", id: "profilePictureInput" },
                on: { change: _vm.handleFileUpload },
              }),
              _c(
                "vs-button",
                {
                  staticClass: "mr-4 sm:mb-0 mb-2",
                  on: {
                    click: function ($event) {
                      return _vm.$refs.profilePictureInput.click()
                    },
                  },
                },
                [_vm._v("Upload photo")]
              ),
              _c(
                "vs-button",
                {
                  attrs: { type: "border", color: "danger" },
                  on: { click: _vm.removeProfilePicture },
                },
                [_vm._v("Remove")]
              ),
              _c("p", { staticClass: "text-sm mt-2" }, [
                _vm._v("Allowed JPG, GIF or PNG. Max size of 800kB"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: { disabled: "", "label-placeholder": "User Name" },
        model: {
          value: _vm.form.email,
          callback: function ($$v) {
            _vm.$set(_vm.form, "email", $$v)
          },
          expression: "form.email",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [
            _vm._v(_vm._s(_vm.$t("account.generalSettings.titleLabel"))),
          ]),
          _c("v-select", {
            attrs: {
              value: _vm.selectedTitle,
              options: _vm.titleOptions,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.setSelectedTitle },
          }),
        ],
        1
      ),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: {
          "label-placeholder": _vm.$t("account.generalSettings.firstNameLabel"),
        },
        model: {
          value: _vm.form.firstName,
          callback: function ($$v) {
            _vm.$set(_vm.form, "firstName", $$v)
          },
          expression: "form.firstName",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: {
          "label-placeholder": _vm.$t("account.generalSettings.lastNameLabel"),
        },
        model: {
          value: _vm.form.lastName,
          callback: function ($$v) {
            _vm.$set(_vm.form, "lastName", $$v)
          },
          expression: "form.lastName",
        },
      }),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: {
          disabled: "",
          "label-placeholder": _vm.$t("account.generalSettings.emailLabel"),
        },
        model: {
          value: _vm.form.email,
          callback: function ($$v) {
            _vm.$set(_vm.form, "email", $$v)
          },
          expression: "form.email",
        },
      }),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [
            _vm._v(_vm._s(_vm.$t("account.generalSettings.dateFormatLabel"))),
          ]),
          _c("v-select", {
            staticClass: "text-sm",
            attrs: {
              reduce: (x) => x.code,
              options: _vm.dateOptions,
              "append-to-body": "",
            },
            model: {
              value: _vm.form.dateFormat,
              callback: function ($$v) {
                _vm.$set(_vm.form, "dateFormat", $$v)
              },
              expression: "form.dateFormat",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Datetime Format")]),
          _c("v-select", {
            staticClass: "text-sm",
            attrs: {
              reduce: (x) => x.code,
              options: _vm.dateTimeOptions,
              "append-to-body": "",
            },
            model: {
              value: _vm.form.dateTimeFormat,
              callback: function ($$v) {
                _vm.$set(_vm.form, "dateTimeFormat", $$v)
              },
              expression: "form.dateTimeFormat",
            },
          }),
        ],
        1
      ),
      !_vm.form.emailConfirmed
        ? _c(
            "vs-alert",
            {
              staticClass: "h-full my-4",
              attrs: {
                "icon-pack": "feather",
                icon: "icon-info",
                color: "warning",
              },
            },
            [
              _c("span", [
                _vm._v(
                  "Your email is not confirmed. Please check your inbox or "
                ),
                _c(
                  "a",
                  {
                    staticClass: "hover:underline",
                    attrs: { href: "#" },
                    on: { click: _vm.sendEmailVerification },
                  },
                  [_vm._v("resend confirmation")]
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c(
            "vs-button",
            { staticClass: "ml-auto mt-4", on: { click: _vm.onSubmit } },
            [_vm._v(_vm._s(_vm.$t("account.generalSettings.saveBtnText")))]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }