var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-6 mb-base" },
    [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Birth Date")]),
          _c("flat-pickr", {
            staticClass: "w-full",
            attrs: { config: { dateFormat: "Y-m-d" } },
            model: {
              value: _vm.form.dob,
              callback: function ($$v) {
                _vm.$set(_vm.form, "dob", $$v)
              },
              expression: "form.dob",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Country")]),
          _c("v-select", {
            attrs: {
              value: _vm.selectedCountry,
              placeholder: "Country",
              options: _vm.countryOptions,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.setSelectedCountry },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("label", { staticClass: "text-sm" }, [_vm._v("Spoken Languages")]),
          _c("v-select", {
            attrs: {
              value: _vm.selectedLanguages,
              multiple: "",
              closeOnSelect: false,
              options: _vm.langOptions,
              dir: _vm.$vs.rtl ? "rtl" : "ltr",
            },
            on: { input: _vm.setSelectedLanguages },
          }),
        ],
        1
      ),
      _c("vs-input", {
        staticClass: "w-full mt-8",
        attrs: { type: "text", "label-placeholder": "Mobile" },
        model: {
          value: _vm.form.mobile,
          callback: function ($$v) {
            _vm.$set(_vm.form, "mobile", $$v)
          },
          expression: "form.mobile",
        },
      }),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c(
            "vs-button",
            { staticClass: "ml-auto mt-4", on: { click: _vm.onSubmit } },
            [_vm._v("Save Changes")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }