var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { pageTitle: "Account Settings", gridWidth: "1/2" } },
    [
      _c("vx-card", [
        _c(
          "div",
          {
            staticClass: "tabs-container px-6 pt-6",
            attrs: { slot: "no-body" },
            slot: "no-body",
          },
          [
            _c(
              "vs-tabs",
              {
                key: _vm.isSmallerScreen,
                staticClass: "tabs-shadow-none",
                attrs: { id: "profile-tabs" },
                model: {
                  value: _vm.selectedTabIndex,
                  callback: function ($$v) {
                    _vm.selectedTabIndex = $$v
                  },
                  expression: "selectedTabIndex",
                },
              },
              [
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      label: !_vm.isSmallerScreen ? "General Settings" : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-general md:ml-0 md:mt-0 mt-0 ml-0" },
                      [_c("account-general-settings")],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-info",
                      label: !_vm.isSmallerScreen ? "Personal Info" : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0" },
                      [_c("account-personal-info")],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-lock",
                      label: !_vm.isSmallerScreen ? "Security" : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0" },
                      [_c("account-security")],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-link-2",
                      label: !_vm.isSmallerScreen ? "Link Accounts" : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-general md:ml-4 md:mt-0 mt-4 ml-0" },
                      [_c("account-linked-accounts")],
                      1
                    ),
                  ]
                ),
                _c(
                  "vs-tab",
                  {
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-bell",
                      label: !_vm.isSmallerScreen ? "Notifications" : "",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tab-text md:ml-4 md:mt-0 mt-4 ml-0" },
                      [_c("account-notifications")],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }