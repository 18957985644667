var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-6 mb-base" },
    [
      _vm.hasLoaded
        ? [
            _vm.form.isGoogleRegistered
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-between items-center mb-8",
                  },
                  [
                    _c("div", [
                      _c("p", [_vm._v("You are connected to google: ")]),
                      _c("p", { staticClass: "font-medium mb-4" }, [
                        _vm._v(_vm._s(_vm.form.googleEmail)),
                      ]),
                    ]),
                    _c(
                      "vs-button",
                      {
                        attrs: { type: "border", color: "danger" },
                        on: { click: _vm.handleUnlinkGoogleAccount },
                      },
                      [_vm._v("Remove")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.form.isGoogleRegistered
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-between items-center mb-8",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-6 ml-auto",
                        attrs: {
                          color: "#00aaff",
                          "icon-pack": "feather",
                          icon: "icon-google",
                        },
                        on: { click: _vm.handleGoogleSignIn },
                      },
                      [_vm._v("Connect To Google")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.form.isFacebookRegistered
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-between items-center mb-8",
                  },
                  [
                    _c("div", [
                      _c("p", [_vm._v("You are connected to facebook: ")]),
                      _c("p", { staticClass: "font-medium mb-4" }, [
                        _vm._v(_vm._s(_vm.form.facebookName)),
                      ]),
                    ]),
                    _c(
                      "vs-button",
                      {
                        attrs: { type: "border", color: "danger" },
                        on: { click: _vm.handleUnlinkFbAccount },
                      },
                      [_vm._v("Remove")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.form.isFacebookRegistered
              ? _c(
                  "div",
                  [
                    _c(
                      "div",
                      { staticStyle: { display: "none" } },
                      [
                        _c("v-facebook-login", {
                          attrs: { "app-id": _vm.facebookAppId },
                          on: {
                            login: _vm.handleFbLogin,
                            "sdk-init": _vm.handleFbSdkInit,
                          },
                          model: {
                            value: _vm.fbSdk.model,
                            callback: function ($$v) {
                              _vm.$set(_vm.fbSdk, "model", $$v)
                            },
                            expression: "fbSdk.model",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-6 ml-auto",
                        attrs: {
                          color: "#3d5afe",
                          "icon-pack": "feather",
                          icon: "icon-facebook",
                        },
                        on: { click: _vm.handleLinkFbAccount },
                      },
                      [_vm._v("Connect To Facebok\n      ")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.form.isTwitterRegistered
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-between items-center mb-8",
                  },
                  [
                    _c("div", [
                      _c("p", [_vm._v("You are connected to twitter: ")]),
                      _c("p", { staticClass: "font-medium mb-4" }, [
                        _vm._v(_vm._s(_vm.form.twitterHandle)),
                      ]),
                    ]),
                    _c(
                      "vs-button",
                      {
                        attrs: { type: "border", color: "danger" },
                        on: { click: _vm.handleUnlinkTwitterAccount },
                      },
                      [_vm._v("Remove")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.form.isTwitterRegistered
              ? _c(
                  "div",
                  {
                    staticClass:
                      "flex flex-wrap justify-between items-center mb-8",
                  },
                  [
                    _c(
                      "vs-button",
                      {
                        staticClass: "mb-6 ml-auto",
                        attrs: {
                          color: "#00aaff",
                          "icon-pack": "feather",
                          icon: "icon-twitter",
                        },
                        on: { click: _vm.handleTwitterSignIn },
                      },
                      [_vm._v("Connect To Twitter")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }