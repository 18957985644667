var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-6 mb-base" },
    [
      _c("h6", { staticClass: "mb-4" }, [_vm._v("Change Password")]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "w-full mt-8",
        attrs: {
          type: "password",
          name: "oldPassword",
          "label-placeholder": "Old Password",
        },
        model: {
          value: _vm.oldPassword,
          callback: function ($$v) {
            _vm.oldPassword = $$v
          },
          expression: "oldPassword",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(
          _vm._s(_vm.errors.first("oldPassword") ? "Old Password Required" : "")
        ),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "w-full mt-8",
        attrs: {
          type: "password",
          name: "newPassword",
          "label-placeholder": "New Password",
        },
        model: {
          value: _vm.newPassword,
          callback: function ($$v) {
            _vm.newPassword = $$v
          },
          expression: "newPassword",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(
          _vm._s(_vm.errors.first("newPassword") ? "New Password Required" : "")
        ),
      ]),
      _c("vs-input", {
        directives: [
          {
            name: "validate",
            rawName: "v-validate",
            value: "required",
            expression: "'required'",
          },
        ],
        staticClass: "w-full mt-8",
        attrs: {
          type: "password",
          name: "confirmNewPassword",
          "label-placeholder": "Confirm Password",
        },
        model: {
          value: _vm.confirmNewPassword,
          callback: function ($$v) {
            _vm.confirmNewPassword = $$v
          },
          expression: "confirmNewPassword",
        },
      }),
      _c("span", { staticClass: "text-danger text-sm" }, [
        _vm._v(
          _vm._s(
            _vm.errors.first("confirmNewPassword")
              ? "Confirm Password Required"
              : ""
          )
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-wrap items-center justify-end" },
        [
          _c(
            "vs-button",
            { staticClass: "ml-auto mt-2", on: { click: _vm.changePassword } },
            [_vm._v("Change Password")]
          ),
        ],
        1
      ),
      _c("h6", { staticClass: "mt-8 mb-4" }, [
        _vm._v(_vm._s(_vm.$t("account.security.twoFactorHeading"))),
      ]),
      _c("label", { staticClass: "text-sm" }, [
        _vm._v("Enable two-factor authentication (2FA) on this account:"),
      ]),
      _c("vs-switch", {
        staticClass: "mt-2",
        model: {
          value: _vm.twoFactorEnabled,
          callback: function ($$v) {
            _vm.twoFactorEnabled = $$v
          },
          expression: "twoFactorEnabled",
        },
      }),
      _vm.twoFactorEnabled && !_vm.twoFactorHasApp
        ? _c(
            "vs-button",
            { staticClass: "mt-4", on: { click: _vm.generateTwoFactorCode } },
            [_vm._v("Setup 2FA Application")]
          )
        : _vm._e(),
      _vm.twoFactorEnabled && _vm.twoFactorHasApp
        ? _c(
            "vs-alert",
            {
              staticClass: "h-full my-4",
              attrs: {
                "icon-pack": "feather",
                icon: "icon-info",
                color: "success",
              },
            },
            [
              _c("span", [
                _vm._v("You have set up your authenticator application. "),
              ]),
              _c(
                "a",
                {
                  staticClass: "hover:underline",
                  attrs: { href: "#" },
                  on: { click: _vm.generateTwoFactorCode },
                },
                [_vm._v("Update authenticator application")]
              ),
              _c("span", [_vm._v(" or ")]),
              _c(
                "a",
                {
                  staticClass: "hover:underline",
                  attrs: { href: "#" },
                  on: { click: _vm.generateBackupCodes },
                },
                [_vm._v("Generate new backup codes")]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Setup Two Factor Authentication",
            "buttons-hidden": "",
            active: _vm.twoFactorPrompt,
          },
          on: {
            cancel: _vm.clearTwoFactorFields,
            close: _vm.clearTwoFactorFields,
            "update:active": function ($event) {
              _vm.twoFactorPrompt = $event
            },
          },
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col w-full" }, [
                  _c("p", [
                    _vm._v(
                      "Use Google Authenticator or any authenticator app to scan this"
                    ),
                  ]),
                ]),
                _c("img", {
                  staticStyle: { margin: "auto" },
                  attrs: { src: _vm.twoFactorDataUrl },
                }),
                _c(
                  "div",
                  { staticClass: "vx-col w-full flex justify-center" },
                  [
                    _c("p", { staticClass: "mt-5" }, [
                      _vm._v("Or enter the key manually: "),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full flex justify-center" },
                  [_c("p", [_vm._v(_vm._s(_vm.formattedTwoFactorDataKey))])]
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full mt-3" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full mt-5",
                      attrs: {
                        name: "validationcode",
                        type: "number",
                        "label-placeholder": "Enter verfication code",
                        placeholder: "Verification code",
                      },
                      model: {
                        value: _vm.twoFactorInputCode,
                        callback: function ($$v) {
                          _vm.twoFactorInputCode = $$v
                        },
                        expression: "twoFactorInputCode",
                      },
                    }),
                  ],
                  1
                ),
                _vm.showInvalidCodeMsg
                  ? _c("div", { staticClass: "vx-col w-full mt-2" }, [
                      _c("span", { staticClass: "text-danger text-sm" }, [
                        _vm._v("Invalid verification code."),
                      ]),
                    ])
                  : _vm._e(),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "ml-auto mt-6",
                  attrs: { color: "danger", type: "flat" },
                  on: { click: _vm.closeTwoFactorDialog },
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "float-right ml-auto mt-6",
                  on: { click: _vm.setupTwoFactorApp },
                },
                [_vm._v("Submit")]
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "vs-prompt",
        {
          attrs: {
            title: "Backup Codes",
            "accept-text": "Done",
            "cancel-text": "Close",
            active: _vm.backupCodePrompt,
          },
          on: {
            "update:active": function ($event) {
              _vm.backupCodePrompt = $event
            },
          },
        },
        [
          _c("div", [
            _c("div", { staticClass: "vx-row" }, [
              _c("div", { staticClass: "vx-col w-full mb-5" }, [
                _c("p", [
                  _vm._v(
                    "List of backup codes. These are one-time used only, but you can always generate new ones."
                  ),
                ]),
              ]),
              _c("div", { staticClass: "vx-col w-1/2" }, [
                _c(
                  "ul",
                  _vm._l(_vm.backupCodes, function (backupCode) {
                    return _c("li", { key: backupCode }, [
                      _vm._v(_vm._s(backupCode)),
                    ])
                  }),
                  0
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }